const urls = {
  apiUrl: process.env.REACT_APP_API_URL,

  URL_LOGIN: "/api/login/",
  URL_logout: "/api/logout/",
  URL_editPasseword: "/resetpassword/",
  URL_GETPERMISSIONS: "/api/liste-permission/",
  URL_GETUSER: "/api/mon-compte/",
  URL_TEAMS: "/api/teams/",

  URL_getTickers: "/api/tickers/liste/",
  URL_GETTICKER: "/api/ticker/infos/",
  URL_GETTICKERYEARINFO: "/api/ticker-calendrier/",
  URL_CONTACTTICKER: "/api/contacts/client/",
  URL_GETAM: "/api/ticker/am/",
  URL_getTickersAlert: "/api/liste-alertes/",
  URL_STATUT_TICKER: "/api/ticker_history/",
  URL_GETUSERS: "/api/all_membre/",
  URL_GETBU: "/api/liste-bu/",
  URL_GETCATEGTYPE: "/api/liste-typecategorie/",
  URL_GETAMDAY: "/api/am_day/",
  URL_GETLETTERTICKER: "/api/lettre/",

  URL_GETTIMESHEETS: "/api/timesheet/",
  URL_GETTEAM: "/api/ticker/team/",
  URL_GETSAVEDTIME: "/api/ticker-membre/",
  URL_GETSPECIAL: "/api/ticker/tickeradmin/",
  URL_GETMYTIMES: "/api/mes-timesheet/",
  URL_GETNEXTDAY: "/api/next-day/",
  URL_GETDAYBEFORE: "/api/test-day-befor/",
  URL_SUIVITIMESHEETS: "/api/timesheet-ajour/",
  URL_CREATETICKER: "/api/create-ticker/",
  URL_UPDATETICKER: "/api/update-ticker/",

  URL_GETSTATSBYWEEK: "/api/timesheetuser/",
  URL_GETSTATSCONTROLS: "/api/info-date-timesheet/",

  URL_FACTURE: "/api/liste-factures/",
  URL_FACTURATION: "/api/facturation/",
  URL_FACTURECLIENT: "/api/factures/client/",
  URL_FACTURESCLIENT: "/api/factures-client/",
  URL_CREATEFACTURE: "/api/create-facture/",
  URL_UPDATEFACTURE: "/api/update-facture/",
  URL_GETALLOCATIONOFFRE: "/api/allocation-offre/",
  URL_STATUTFACTURE: "/api/statut-facture/",
  URL_ENTITEENTREPRISE: "/api/entreprise/",
  URL_REVENUE: "/api/pourcentage-revenu/",
  URL_FACTURESTICKERS: "/api/factures-ticker/",
  URL_INFODATETICKER: "/api/infodatefacture/",
  URL_LISTEALLOCATIONS: "/api/liste-allocations/",
  URL_DEPENSES: "/api/depenses-ticker/",

  URL_LISTEFOURNISSEUR: "/api/liste-fournisseurs/",
  URL_LISTEFOURNISSEUR_V2: "/api/facturation-fournisseur/fournisseur/",
  URL_ADDCONTACTSFOURNISSEUR: "/api/facturation-fournisseur/contact/",
  URL_ADDFOURNISSEUR: "/api/create-fournisseur/",
  URL_ADDFOURNISSEUR_V2: "/api/facturation-fournisseur/fournisseur/",
  // URL_ADDFILESFOURNISSEUR: "/api/facturation-fournisseur/fichier/",
  URL_ADDFILESFOURNISSEUR: "/api/create-fichier-fournisseur/",
  URL_UPDATEFILESFOURNISSEUR: "/api/update-fichier-fournisseur/",
  URL_UPDATEFOURNISSEUR: "/api/update-fournisseur/",
  URL_CATEGORIEFOURNISSEUR_V2: "/api/facturation-fournisseur/categorie/",
  URL_CATEGORIEFOURNISSEUR: "/api/categorie-fournisseur/",
  URL_SOUSCATEGORIEFOURNISSEUR: "/api/souscategorie-fournisseur/",
  URL_SOUSCATEGORIEFOURNISSEUR_V2: "/api/facturation-fournisseur/souscategorie/",
  URL_FILESFOURNISSEUR: "/api/fichier-fournisseur/",
  URL_CUSTOMVALUESFOURNISSEUR: "/api/custom-values-fournisseur/",
  URL_CONTACTFOURNISSEUR: "/api/contact-fournisseur/",
  URL_CUSTOMVALUES: "/api/custom-value/",

  URL_FACTURE_FOURNISSEUR: "/api/liste-factures-fournisseur/",
  URL_ALLOCATIONS_FOURNISSEUR: "/api/liste-allocations-fournisseur/",
  URL_STATUT_FACTURE_FOURNISSEUR: "/api/statut-facture-fournisseur/",
  URL_ALLOCATIONS_OFFRE_FOURNISSEUR: "/api/allocation-offre-fournisseur/",
  URL_REVENUE_FOURNISSEUR: "/api/pourcentage-revenu-fournisseur/",
  URL_CREATE_FACTURE_FOURNISSEUR: "/api/create-facture-fournisseur/",
  URL_UPDATE_FACTURE_FOURNISSEUR: "/api/update-facture-fournisseur/",

  URL_CONGE: "/api/demandes-conge/",
  URL_TEAM_SOLDE: "/api/solde-conge/",
  URL_CONGE_SUB: "/api/my-team-demandes-conge/",
  URL_PDF_CONGE: "/api/pdf-demande/",
  URL_VALIDATE_CONGE: "/api/validation-demandes-conge/",
  URL_GAIN_CONGE: "/api/mes-gains/",


  URL_GETLISTEMARQUE: "/api/liste-marques/",
  URL_DETAILMARQUE: "/api/marque/infos/",
  URL_UPDATEMARQUE: "/api/update-marque/",

  URL_DETAILCLIENT: "/api/client/infos/",
  URL_UPDATECLIENT: "/api/update-client/",
  URL_GETCLIENTS: "/api/liste-clients/",
  URL_GETRAPPORTCLIENT: "/api/rapport-by-marque/",
  URL_ADDCONTACTCLIENTS: "/admin/client/contact/",
  URL_GETTICKERSCLIENTS: "/api/liste-tickers-client/",

  URL_ATTESTATION: "/api/demandes-attestation/",
  URL_PDF_ATTESTATION: "/api/pdf-attestation/",
  URL_TYPE_DEMANDE: "/api/types-demande/",

  URL_MALADIE: "/api/maladie/",

  URL_TIMESHEETUSERTICKER: "/api/timesheetuserticker/",
  URL_CREATEDEMANDEEXPLICATION: "/api/create-demande/",
  URL_CREATERESPONSEEXPLICATION: "/api/create-response/",
  URL_LISTEXPLICATION: "/api/list-demande-explication/",
  URL_UPDATEDEMANDEEXPLICATION: "/api/update-demande/",

  URL_CREATE_ORDRE: "/api/create-ordre/",
  URL_CREATE_REFUND: "/api/create-refund/",
  URL_UPDATE_ORDRE: "/api/update-ordre/",
  URL_UPDATE_REFUND: "/api/update-refund/",
  URL_LIST_ORDRE: "/api/liste-ordres/",
  URL_CATEGORIEREMBOURSEMENT: "/api/refund-category/",
  URL_STATUTORDRE: "/api/ordre-status/",
  URL_STATUTREMBOURSEMENT: "/api/refund-status/",
  URL_MOYEN_REMBOURSEMENT: "/api/refund-means/",
  URL_MOYEN_PAIEMNT: "/api/payment-means/",

  URL_SEND_MESSAGE_REMPL: "/api/demande-remplissage/",

  URL_TEAM_DISPONIBLE: "/api/list-nonstaffe/",
  URL_NON_STAFFE: "/api/create-nonstaffe/",
  URL_UPDATE_NON_STAFFE: "/api/update-nonstaffe/",

  URL_getsSuperTickers: "/api/supertickers/liste/",
  URL_CREATESUPERTICKER: "/api/create-demandersuperticke/",
  URL_getContactTicker: "/api/ticker/contacts/",
  URL_ADDCONTACTTICKER: "/admin/ticker/tickercontact/",
  URL_ADDMEMBRETICKER: "/admin/ticker/tickermembre/",
  URL_VALIDERTICKER: "/admin/ticker/demandeticker/",
  URL_GETDEPLOIMENTS: "/api/ticker/deploiement/",
  URL_CREATEDEPLOIMENT: "/api/ticker/create-deploiement/",
  URL_EDITEDEPLOIMENT: "/api/ticker/update-deploiement/",
  URL_UPDATEDEPLOIMENT: "/api/update-deploiement/",
  URL_VALIDERDEMANDE: "/api/valider-demande-ticker/",
  URL_REJETDEMANDE: "/api/rejeter-demande-ticker/",
  URL_UPDATEDEMANDE: "/api/update-ticker/",
  URL_GETSUPERDEMANDES: "/api/liste-demande-superticker/",
  URL_VALISUPERDERDEMANDE: "/api/valider-demande-superticker/",
  URL_REJETSUPERDEMANDE: "/api/rejeter-demande-superticker/",
  URL_UPDATESUPERDEMANDE: "/api/update-superticker/",
  URL_UPDATECOMMANDES: "/api/update-demande/",
  URL_REGITRAR: "/api/liste-registrar/",
  URL_CREATETICKERADMIN: "/api/create-ticker/",
  URL_CREATESUPERTICKERADMIN: "/api/create-superticker/",
  URL_ADDGA: "/api/ticker/googleanalytics/",
  URL_CREATEDEMANDE: "/api/liste-demande/",
  URL_GETDCOMANDE: "/api/liste-demande-it/",
  URL_GETDDETAILCOMANDE: "/api/detail-demande/",
  URL_RESSOURCE: "/api/liste-ressource/",
  URL_LISTEDNS: "/api/liste-dns/",
  URL_LISTESERVEURS: "/api/liste-serveur-deploiment/",
  URL_LISTIPS: "/api/liste-ip-serveur/",
  URL_LISTENV: "/api/liste-environnement/",
  URL_LISTECONTACTS: "/api/liste-contacts/",
  URL_DOWNLOADRESSOURCES: "/api/telecharger-ressource/",
  URL_GETPHASES: "/api/phase-projet/",
  URL_DELETESAVEDTIME: "/api/delete_membre_ticker/",
  URL_GETALLPHASES: "/api/all-phase/",
  URL_TICKERALLOCATION: "/api/ticker-allocation/",
  URL_LISTEALLOCATION: "/api/list-allocation/",
  URL_DELETEALLOCATION: "/api/delete-allocation/",
  URL_UPDATEALLOCATION: "/api/update-allocation/",
  URL_CREATEALLOCATION: "/api/create-allocation/",
  URL_CREATEALLOCATIONPHASE: "/api/create-allocation-phase/",
  URL_DELETEALLOCATIONPHASE: "/api/delete-allocation-phase/",
  URL_UPDATEALLOCATIONPHASE: "/api/update-allocation-phase/",
  URL_CREATESOUSALLOCATIONPHASE: "/api/create-sous-allocation-phase/",
  URL_DELETESOUSALLOCATIONPHASE: "/api/delete-sous-allocation-phase/",
  URL_UPDATESOUSALLOCATIONPHASE: "/api/update-sous-allocation-phase/",
  URL_MESALLOCATIONS: "/api/mes-allocation/",
  URL_GETCOLLABORATEURREPORTING: "/api/user-allocation/",
  URL_SUPERTICKERALLOCATION: "/api/list-superticker/",
  URL_TICKERBYSUPERALLOCATION: "/api/ticker-superticker/",
  URL_UPDATEDOMAINE: "/api/update-domaine/",
  URL_GETDOMAINENODEP: "/api/liste-domaine-without-deploiement/",
  URL_ACHAT: "/api/achat/",
  URL_FOURNISSEUR: "/api/fornisseur/",
  URL_DEVIS: "/api/devis/",
}

export default urls
