import { call, takeEvery } from "redux-saga/effects"
import {
  add_facture_fournisseur_action_done,
  add_files_fournisseur_action_done,
  add_contacts_fournisseur_action_done,
  add_fournisseur_action_done,
  info_fournisseur_action_done,
  liste_allocation_offre_facture_fournisseur_action_done,
  liste_allocation_offre_fournisseur_action_done,
  liste_categorie_fournisseur_action_done,
  liste_contact_fournisseur_action_done,
  liste_custom_values_action_done,
  liste_custom_values_fournisseur_action_done,
  liste_factures_fournisseur_action_done,
  liste_files_fournisseur_action_done,
  liste_fournisseur_action_done,
  liste_fournisseur_all_action_done,
  liste_sous_categorie_fournisseur_action_done,
  statut_facture_fournisseur_action_done,
  update_facture_fournisseur_action_done,
  update_files_fournisseur_action_done,
  update_fournisseur_action_done
} from "../actions/fournisseur"
import urls from "../services/api"
import { callApi } from "../services/Callapi"
import { get, patch, post } from "../services/request"
import moment from "moment"

moment.locale("fr")

// liste_fournisseur
function* liste_fournisseur_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_LISTEFOURNISSEUR}${action.payload.page && "?page=" + action.payload.page}${action.payload.textRecherche && "&search=" + action.payload.textRecherche}${action.payload.categorie && "&categorie=" + action.payload.categorie}${action.payload.Typecategorie && "&sous_categorie=" + action.payload.Typecategorie}`,
    get,
    null,
    liste_fournisseur_action_done
  )
}

export function* watch_liste_fournisseurs_saga() {
  yield takeEvery("LISTE_FOURNISSEUR", liste_fournisseur_saga)
}

// liste_fournisseur_all
function* liste_fournisseur_all_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_LISTEFOURNISSEUR}`,
    get,
    null,
    liste_fournisseur_all_action_done
  )
}

export function* watch_liste_fournisseurs_all_saga() {
  yield takeEvery("LISTE_FOURNISSEUR_ALL", liste_fournisseur_all_saga)
}

// info_fournisseur
function* info_fournisseur_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls}${action.payload}/`,
    get,
    null,
    info_fournisseur_action_done
  )
}

export function* watch_info_fournisseurs_saga() {
  yield takeEvery("INFO_FOURNISSEUR", info_fournisseur_saga)
}

// add_fournisseur
function* add_fournisseur_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_ADDFOURNISSEUR}`,
    post,
    action.payload,
    add_fournisseur_action_done
  )
}

export function* watch_add_fournisseurs_saga() {
  yield takeEvery("ADD_FOURNISSEUR", add_fournisseur_saga)
}

// update_fournisseur
function* update_fournisseur_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_UPDATEFOURNISSEUR}${action.payload.id}/`,
    patch,
    action.payload.data,
    update_fournisseur_action_done
  )
}

export function* watch_update_fournisseurs_saga() {
  yield takeEvery("UPDATE_FOURNISSEUR", update_fournisseur_saga)
}

// liste_custom_values_fournisseur
function* liste_custom_values_fournisseur_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CUSTOMVALUESFOURNISSEUR}${action.payload.id ? "?fournisseur=" + action.payload.id : ""}${action.payload.page ? "&page=" + action.payload.page : ""}`,
    get,
    null,
    liste_custom_values_fournisseur_action_done
  )
}

export function* watch_liste_custom_values_fournisseurs_saga() {
  yield takeEvery("CUSTOM_VALUES_FOURNISSEUR", liste_custom_values_fournisseur_saga)
}

// liste_contact_fournisseur
function* liste_contact_fournisseur_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONTACTFOURNISSEUR}${action.payload.id ? "?fournisseur=" + action.payload.id : ""}`,
    get,
    null,
    liste_contact_fournisseur_action_done
  )
}

export function* watch_liste_contact_fournisseurs_saga() {
  yield takeEvery("CONTACT_FOURNISSEUR", liste_contact_fournisseur_saga)
}

// liste_files_fournisseur
function* liste_files_fournisseur_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_FILESFOURNISSEUR}${action.payload.id ? "?fournisseur=" + action.payload.id : ""}${action.payload.page ? "&page=" + action.payload.page : ""}`,
    get,
    null,
    liste_files_fournisseur_action_done
  )
}

export function* watch_liste_files_fournisseurs_saga() {
  yield takeEvery("FILES_FOURNISSEUR", liste_files_fournisseur_saga)
}

// add_files_fournisseur
function* add_files_fournisseur_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_ADDFILESFOURNISSEUR}`,
    post,
    action.payload,
    add_files_fournisseur_action_done
  )
}

export function* watch_add_files_fournisseurs_saga() {
  yield takeEvery("ADD_FILES_FOURNISSEUR", add_files_fournisseur_saga)
}

// add_contacts_fournisseur
function* add_contacts_fournisseur_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_ADDCONTACTSFOURNISSEUR}`,
    post,
    action.payload,
    add_contacts_fournisseur_action_done
  )
}

export function* watch_add_contacts_fournisseurs_saga() {
  yield takeEvery("ADD_CONTACTS_FOURNISSEUR", add_contacts_fournisseur_saga)
}
// update_files_fournisseur
function* update_files_fournisseur_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_UPDATEFILESFOURNISSEUR}${action.payload.id}/`,
    patch,
    action.payload.data,
    update_files_fournisseur_action_done
  )
}

export function* watch_update_files_fournisseurs_saga() {
  yield takeEvery("UPDATE_FILES_FOURNISSEUR", update_files_fournisseur_saga)
}

// liste_categorie_fournisseur
function* liste_categorie_fournisseur_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CATEGORIEFOURNISSEUR}`,
    get,
    null,
    liste_categorie_fournisseur_action_done
  )
}

export function* watch_liste_categorie_fournisseurs_saga() {
  yield takeEvery("CATEGORIE_FOURNISSEUR", liste_categorie_fournisseur_saga)
}

// liste_categorie_fournisseur
function* liste_sous_categorie_fournisseur_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_SOUSCATEGORIEFOURNISSEUR}${action.payload?.categorie ? "?categorie=" + action.payload.categorie : ""}`,
    get,
    null,
    liste_sous_categorie_fournisseur_action_done
  )
}

export function* watch_liste_sous_categorie_fournisseurs_saga() {
  yield takeEvery("SOUS_CATEGORIE_FOURNISSEUR", liste_sous_categorie_fournisseur_saga)
}

// liste_custom_values
function* liste_custom_values_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CUSTOMVALUES}`,
    get,
    null,
    liste_custom_values_action_done
  )
}

export function* watch_liste_custom_values_saga() {
  yield takeEvery("CUSTOM_VALUES", liste_custom_values_saga)
}

// liste_facture
function* liste_facture_fournisseur_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_FACTURE_FOURNISSEUR}?page=${action.payload.page}${action.payload.search ? "&search=" + action.payload.search : ""}${action.payload.fournisseur ? "&fournisseur=" + action.payload.fournisseur : ""}${action.payload.paye !== "" ? "&paye=" + action.payload.paye : ""}${action.payload.marque ? "&marque=" + action.payload.marque : ""}${action.payload.allocation ? "&allocation_offre=" + action.payload.allocation : ""}${action.payload.am ? "&am=" + action.payload.am : ""}${action.payload.entite ? "&entite=" + action.payload.entite : ""}${action.payload.tri.length > 0 ? "&tri=[" + action.payload.tri + "]" : ""}${action.payload.nouveau ? "&nouveau=True" : ""}${action.payload.devis ? "&devis=True" : ""}${action.payload.bc ? "&bc=True" : ""}${action.payload.facturee ? "&facturee=True" : ""}${action.payload.avoir ? "&facture_avoir=True" : ""}${action.payload.abandonne ? "&abandonne=True" : ""}${action.payload.afacturer ? "&en_attente=True" : ""}${action.payload.doc_accuse ? "&doc_accuse=True" : ""}${action.payload.doc_bc ? "&doc_bc=True" : ""}${action.payload.doc_devis ? "&doc_devis=True" : ""}${action.payload.doc_facture ? "&doc_facture=True" : ""}${action.payload.doc_accuse_none ? "&not_doc_accuse=True" : ""}${action.payload.doc_bc_none ? "&not_doc_bc=True" : ""}${action.payloaddoc_devis_none ? "&not_doc_devis=True" : ""}${action.payload.doc_facture_none ? "&not_doc_facture=True" : ""}${action.payload.DateAllocation ? "&allocation_date=" + moment(action.payload.DateAllocation).format("MMMM-YYYY") : ""}${action.payload.DateDebut ? "&date_debut=" + moment(action.payload.DateDebut).format("YYYY-MM-DD") : ""}${action.payload.DateFin ? "&date_fin=" + moment(action.payload.DateFin).format("YYYY-MM-DD") : ""}`,
    get,
    null,
    liste_factures_fournisseur_action_done
  )
}

export function* watch_liste_facture_fournisseur_saga() {
  yield takeEvery("LISTE_FACTURE_FOURNISSEUR", liste_facture_fournisseur_saga)
}

// add_facture
function* add_facture_fournisseur_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CREATE_FACTURE_FOURNISSEUR}`,
    post,
    action.payload,
    add_facture_fournisseur_action_done
  )
}

export function* watch_add_facture_fournisseur_saga() {
  yield takeEvery("ADD_FACTURE_FOURNISSEUR", add_facture_fournisseur_saga)
}


// update_facture
function* update_facture_fournisseur_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_UPDATE_FACTURE_FOURNISSEUR}${action.payload.id}/`,
    patch,
    action.payload.data,
    update_facture_fournisseur_action_done
  )
}

export function* watch_update_facture_fournisseur_saga() {
  yield takeEvery("UPDATE_FACTURE_FOURNISSEUR", update_facture_fournisseur_saga)
}

// liste_allocation_offre_facture
function* liste_allocation_offre_facture_fournisseur_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_ALLOCATIONS_FOURNISSEUR}?facture=${action.payload}`,
    get,
    null,
    liste_allocation_offre_facture_fournisseur_action_done
  )
}

export function* watch_liste_allocation_offre_facture_fournisseur_saga() {
  yield takeEvery("ALLOCATION_OFFRE_FACTURE_FOURNISSEUR", liste_allocation_offre_facture_fournisseur_saga)
}

// liste_allocation_offre
function* liste_allocation_offre_fournisseur_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_ALLOCATIONS_OFFRE_FOURNISSEUR}`,
    get,
    null,
    liste_allocation_offre_fournisseur_action_done
  )
}

export function* watch_liste_allocation_offre_fournisseur_saga() {
  yield takeEvery("ALLOCATION_OFFRE_FOURNISSEUR", liste_allocation_offre_fournisseur_saga)
}


// statut_facture
function* statut_facture_fournisseur_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_STATUT_FACTURE_FOURNISSEUR}`,
    get,
    null,
    statut_facture_fournisseur_action_done
  )
}

export function* watch_statut_facture_fournisseur_saga() {
  yield takeEvery("STATUT_FACTURE_FOURNISSEUR", statut_facture_fournisseur_saga)
}
