import { call, takeEvery } from "redux-saga/effects"
import {
  add_facture_action_done,
  entite_action_done,
  facturation_action_done,
  facture_ticker_action_done,
  liste_allocation_offre_action_done,
  liste_allocation_offre_facture_action_done,
  liste_factures_action_done,
  revenue_liste_action_done,
  statistique_facture_action_done,
  statut_facture_action_done,
  update_facture_action_done
} from "../actions/facture"
import urls from "../services/api"
import { callApi } from "../services/Callapi"
import { get, patch, post } from "../services/request"
import moment from "moment"

moment.locale("fr")


// liste_facture
function* liste_facture_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_FACTURE}?page=${action.payload.page}${action.payload.search ? "&search=" + action.payload.search : ""}${action.payload.paye !== "" ? "&paye=" + action.payload.paye : ""}${action.payload.marque ? "&marque=" + action.payload.marque : ""}${action.payload.allocation ? "&allocation_offre=" + action.payload.allocation : ""}${action.payload.am ? "&am=" + action.payload.am : ""}${action.payload.entite ? "&entite=" + action.payload.entite : ""}${action.payload.tri ? "&tri=[" + action.payload.tri + "]" : ""}${action.payload.future ? "&future=True" : ""}${action.payload.facture ? "&facture=True" : ""}${action.payload.lead ? "&lead=True" : ""}${action.payload.abandonne ? "&abandonne=True" : ""}${action.payload.virtuelle ? "&virtuelle=True" : ""}${action.payload.avoir ? "&facture_avoir=True" : ""}${action.payload.compense_avoir ? "&compense_avoir=True" : ""}${action.payload.afacturer ? "&en_attente=True" : ""}${action.payload.doc_accuse ? "&doc_accuse=True" : ""}${action.payload.doc_bc ? "&doc_bc=True" : ""}${action.payload.doc_devis ? "&doc_devis=True" : ""}${action.payload.doc_facture ? "&doc_facture=True" : ""}${action.payload.doc_accuse_none ? "&not_doc_accuse=True" : ""}${action.payload.doc_bc_none ? "&not_doc_bc=True" : ""}${action.payloaddoc_devis_none ? "&not_doc_devis=True" : ""}${action.payload.doc_facture_none ? "&not_doc_facture=True" : ""}${action.payload.DateAllocation ? "&allocation_date=" + moment(action.payload.DateAllocation).format("MMMM-YYYY") : ""}${action.payload.DateDebut ? "&date_debut=" + moment(action.payload.DateDebut).format("YYYY-MM-DD") : ""}${action.payload.DateFin ? "&date_fin=" + moment(action.payload.DateFin).format("YYYY-MM-DD") : ""}`,
    get,
    null,
    liste_factures_action_done
  )
}

export function* watch_liste_facture_saga() {
  yield takeEvery("LISTE_FACTURE", liste_facture_saga)
}


// liste_facturation
function* liste_facturation_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_FACTURECLIENT}?${action.payload.search ? "?search=" + action.payload.search : ""}${action.payload.date.length > 0 ? "&year=[" + action.payload.date + "]" : ""}${action.payload.am.length > 0 ? "&am=[" + action.payload.am + "]" : ""}${action.payload.entite.length > 0 ? "&entite=[" + action.payload.entite + "]" : ""}${action.payload.future ? "&future=True" : ""}${action.payload.facture ? "&facturee=True" : ""}${action.payload.virtuelle ? "&virtuelle=True" : ""}${action.payload.avoir ? "&facture_avoir=True" : ""}${action.payload.afacturer ? "&en_attente=True" : ""}${action.payload.lead ? "&lead=True" : ""}${action.payload.abandonne ? "&abandonne=True" : ""}${action.payload.compense_avoir ? "&compense_avoir=True" : ""}`,
    get,
    null,
    facturation_action_done
  )
}

export function* watch_liste_facturation_saga() {
  yield takeEvery("FACTURATION", liste_facturation_saga)
}


// liste_allocation_offre
function* liste_allocation_offre_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETALLOCATIONOFFRE}`,
    get,
    null,
    liste_allocation_offre_action_done
  )
}

export function* watch_liste_allocation_offre_saga() {
  yield takeEvery("ALLOCATION_OFFRE", liste_allocation_offre_saga)
}


// liste_allocation_offre_facture
function* liste_allocation_offre_facture_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_LISTEALLOCATIONS}?facture=${action.payload}`,
    get,
    null,
    liste_allocation_offre_facture_action_done
  )
}

export function* watch_liste_allocation_offre_facture_saga() {
  yield takeEvery("ALLOCATION_OFFRE_FACTURE", liste_allocation_offre_facture_saga)
}


// entite
function* entite_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_ENTITEENTREPRISE}`,
    get,
    null,
    entite_action_done
  )
}

export function* watch_entite_saga() {
  yield takeEvery("ENTITE", entite_saga)
}


// statut_facture
function* statut_facture_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_STATUTFACTURE}`,
    get,
    null,
    statut_facture_action_done
  )
}

export function* watch_statut_facture_saga() {
  yield takeEvery("STATUT_FACTURE", statut_facture_saga)
}


// revenue_liste
function* revenue_liste_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_REVENUE}`,
    get,
    null,
    revenue_liste_action_done
  )
}

export function* watch_revenue_liste_saga() {
  yield takeEvery("REVENUE_LIST", revenue_liste_saga)
}


// add_facture
function* add_facture_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CREATEFACTURE}`,
    post,
    action.payload,
    add_facture_action_done
  )
}

export function* watch_add_facture_saga() {
  yield takeEvery("ADD_FACTURE", add_facture_saga)
}


// update_facture
function* update_facture_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_UPDATEFACTURE}${action.payload.id}/`,
    patch,
    action.payload.data,
    update_facture_action_done
  )
}

export function* watch_update_facture_saga() {
  yield takeEvery("UPDATE_FACTURE", update_facture_saga)
}


// facture_ticker
function* facture_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_FACTURESTICKERS}?date=${action.payload.date}&ticker=${action.payload.id}`,
    get,
    null,
    facture_ticker_action_done
  )
}

export function* watch_facture_ticker_saga() {
  yield takeEvery("FACTURE_TICKER", facture_ticker_saga)
}


// statistique_facture
function* statistique_facture_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_INFODATETICKER}?date=${action.payload.date}&ticker=${action.payload.id}`,
    get,
    null,
    statistique_facture_action_done
  )
}

export function* watch_statistique_facture_saga() {
  yield takeEvery("STATISTIQUE_FACTURE", statistique_facture_saga)
}
